.nano-card {
	flex: 1;
	display: flex;
	flex-direction: column;
	margin: 20px;
	border-radius: 10px;
	background: #4E91E0;
	position: relative;
	overflow: hidden;

	height: 11vw;
	min-height: 155px;
	@media (max-width: 939px) {
		height: 155px;
		min-height: unset;
	}

	&.is-wallet {
		@wallet-card-background-color: #2F3241;
		background: @wallet-card-background-color;

		> .logo {
			filter: hue-rotate(15deg) saturate(0.6) brightness(0.40);
		}

		&::after {
			content: '';
			position: absolute;
			width: calc(100% - 10px);
			height: calc(100% - 10px);
			display: block;
			border: 1px dashed #555573;
			margin: 4px;
			border-radius: 10px;
			pointer-events: none;
		}

		> .card-contents > .account-name-container > .select-button.open > .circle {
			background: @wallet-card-background-color;
		}
	}

	&.incoming-card {
		background: #272837;

		min-height: 125px;
		height: 125px;

		@media (min-width: 1500px) {
			min-height: 140px;
			height: 140px;
		}
	}

	> .logo {
		position: absolute;
		width: 100%;
		height: 100%;
		background: url('../../assets/img/nano-mark.svg');
		background-size: 170%;
		background-position: 29% 30%;
		background-repeat: no-repeat;
	}

	> .card-contents {
		position: absolute;
		width: 100%;
		height: 100%;
	}

	> .card-contents > .account-name-container {
		display: flex;
		align-items: center;
		margin: 14px 12px 13px 17px;

		> .name {
			color: #FFF;
			margin-right: 10px;
			border-bottom: 2px solid transparent;
			padding-top: 1px;
			.truncated-text();

			font-size: 14px;
			@media (min-width: 1500px) {
				font-size: 18px;
			}
		}

		> a.name {
			text-decoration: none;
			transition: border-color 100ms ease-in-out;

			&:hover {
				border-color: #FFF;
			}
		}

		> .select-button {
			display: flex;
			margin-top: 1px;
			padding: 9px 8px 9px 5px;
			cursor: pointer;
			border: 2px solid transparent;
			border-radius: 20px;
			transition: background-color 100ms ease-in-out, border-color 100ms ease-in-out;

			&:hover, &.open {
				border-color: #FFF;
			}

			&.open {
				background: #FFF;

				> .circle {
					background: #5D9BE4;
				}
			}

			> .circle {
				width: 5px;
				height: 5px;
				border-radius: 100%;
				background: #FFF;
				margin-left: 3px;
			}
		}
	}

	> .card-contents > .account-balances > .spinner-container {
		display: flex;
		margin: 25px 12px 20px 17px;

		.uk-spinner {
			color: #FFF;
		}
	}

	> .card-contents > .account-balances > .balance-container {
		display: flex;
		align-items: flex-end;
		margin: 0 12px 20px 17px;
		line-height: 1;

		&.primary {
			margin-bottom: 12px;
			color: #FFF;

			> .currency-name {
				font-size: 18px;
				margin-bottom: -1px;
				@media (min-width: 1500px) {
					font-size: 22px;
					margin-bottom: 0;
				}
			}

			> .amount-container {
				> .amount-integer {
					font-weight: 700;

					font-size: 26px;
					margin-bottom: -2px;
					@media (min-width: 1500px) {
						font-size: 32px;
						margin-bottom: -1px;
					}
				}

				> .amount-fractional {
					font-size: 16px;
					@media (min-width: 1500px) {
						font-size: 21px;
					}
				}
			}
		}

		&.converted {
			color: #DEEDFF;

			> .currency-name {
				font-size: 12px;
				margin-bottom: 0;
				@media (min-width: 1500px) {
					font-size: 12px;
					margin-bottom: 1px;
				}

				> .estimate-symbol {
					margin-right: 2px;
				}
			}

			> .amount-container {
				> .amount-integer {
					margin-right: 1px;
					margin-bottom: -1px;

					font-size: 16px;
					@media (min-width: 1500px) {
						font-size: 18px;
					}
				}

				> .amount-fractional {
					font-size: 15px;

					font-size: 12px;
					@media (min-width: 1500px) {
						font-size: 15px;
					}
				}
			}
		}

		> .currency-name {
			width: 41px;
			@media (min-width: 1500px) {
				width: 49px;
			}

			margin-right: 15px;
			font-weight: 300;
			text-align: right;
		}

		> .amount-container {
			display: flex;
			align-items: flex-end;
			overflow: hidden;

			> .amount-fractional {
				.truncated-text();
			}

			> .amount-updating {
				padding-bottom: 5px;
				margin-left: 12px;
			}
		}
	}
}

.content-container .nano-card {
	margin: 0;
	margin-bottom: 20px;

	@media (min-width: 940px) {
		display: none;

		& + h2 {
			margin-top: 0;
		}
	}
}
