// Variables
// ========================================================================

@tooltip-z-index:                               @global-z-index + 30;
@tooltip-max-width:                             200px;
@tooltip-padding-vertical:                      3px;
@tooltip-padding-horizontal:                    6px;
@tooltip-background:                            #666;
@tooltip-border-radius:                         @global-border-radius-small;
@tooltip-color:                                 @global-inverse-color;
@tooltip-font-size:                             15px;

@tooltip-margin:                                10px;

.uk-tooltip {
    box-shadow: 0 10px 20px 0 #22222220;
}
