// Variables
// ========================================================================

@table-margin-vertical:                         @global-margin;

@table-cell-padding-vertical:                   16px;
@table-cell-padding-horizontal:                 12px;

@table-header-cell-font-size:                   @global-font-size;
@table-header-cell-font-weight:                 normal;
@table-header-cell-color:                       @global-color;
@table-header-cell-background:                  @global-background;

@table-footer-font-size:                        @global-small-font-size;

@table-caption-font-size:                       @global-small-font-size;
@table-caption-color:                           @global-muted-color;

@table-row-active-background:                   #ffd;

@table-divider-border-width:                    @global-border-width;
@table-divider-border:                          @global-border;

@table-striped-row-background:                  #fff;

@table-hover-row-background:                    @table-row-active-background;

@table-small-cell-padding-vertical:             15px;
@table-small-cell-padding-horizontal:           12px;

@table-large-cell-padding-vertical:             20px;
@table-large-cell-padding-horizontal:           12px;

@table-expand-min-width:                        150px;

.uk-table thead {
	background: @table-header-cell-background;
}

.uk-table {
    overflow: hidden;
}
